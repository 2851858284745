import React,{useState, useEffect,useMemo} from 'react'
import accueilim from '../Assets/accueilImage.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AccueilImage2 from '../Assets/accueilImage2.png'
import GoogleMapTir from '../Assets/googleMap.svg'
import GoogleGmail from '../Assets/GmailGoogle.svg'


const Accueil = () => {
    const texts = useMemo(() => [  "ENTREPRISE AVEC TIR",  "SOCIETE AVEC TIR",  "START-UP AVEC TIR"], []);

const [index, setIndex] = useState(0);
const [text, setText] = useState(texts[index]);
const [lettersToShow, setLettersToShow] = useState(0);

useEffect(() => {

  document.title = `TIR | Bienvenue sur notre site`;
  let animationTimeout;

  const startAnimation = () => {
    animationTimeout = setTimeout(() => {
      const nextIndex = index === texts.length - 1 ? 0 : index + 1;
      setIndex(nextIndex);
      setText(texts[nextIndex]);
      setLettersToShow(0);
    }, 3000);
  };

  startAnimation();

  return () => clearTimeout(animationTimeout);
}, [index, texts]);

useEffect(() => {
  if (lettersToShow < text.length) {
    const timeoutId = setTimeout(() => {
      setLettersToShow(lettersToShow + 1);
    }, 100);
    return () => clearTimeout(timeoutId);
  }
}, [lettersToShow, text]);


    
  return (
    <div id="Accueil" className='Accueilmoli'>
        <div className="Accueil">
                <div className="text1 AnimationDiv">
                <div className="textWelcome">
                    <span >
                        Des solutions digitales <br /> innovantes pour votre <br /> 
                        <span className="verteEntreprise TextCarousel PlusieurEntre">
                            {text}
                        </span>
                    </span>
                </div>
                <div className="btn">
                    <a href="#Contacts"><button className='Contact-nousAccueil'>Contactez-nous</button></a>
                </div>
                <div className="iconmedia">
                        <a href="https://www.linkedin.com/company/tir-ci/?trk=public_profile_experience-item_profile-section-card_subtitle-click&originalSubdomain=ci" target="_blank" rel="noreferrer">

                            <div className="iconLink1">
                              
                                <LinkedInIcon className='iconIm' />
                            
                              
                          </div>
                        </a>
                        <a rel="noreferrer" href="https://www.google.com/search?q=localisation%20entreprise%20TIR%20vitib&sxsrf=AJOqlzXlXpnbfltd6gaWTGrgw7RCqBFmYQ:1679659858813&ei=RJMdZNzeFYCZkdUP3Pq2yAs&ved=2ahUKEwjMienJxPT9AhVaVaQEHfglCtIQvS56BAgJEAE&uact=5&oq=localisation+entreprise+TIR+vitib&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABOgoIABBHENYEELADOgcIABCKBRBDOg0ILhCKBRDHARDRAxBDOgsIABCABBCxAxCDAToKCAAQigUQsQMQQzoKCAAQigUQyQMQQzoICAAQgAQQkgM6CAgAEIoFEJIDOgoIABCABBAUEIcCOggIABCABBCxAzoLCAAQigUQkgMQuAQ6BQgAEIAEOgoIABCABBCxAxAKOgcIABCABBAKOgoIABANEIAEELEDOgcIABANEIAEOgoIABCABBBGEP8BOgYIABAWEB46CggAEBYQHhAPEAo6CAgAEBYQHhAPOggIABAWEB4QCjoHCCEQoAEQCkoECEEYAFDhCljFUmD1VGgDcAF4AIABrgOIAfc7kgELMC4xNS4xMy4zLjKYAQCgAQHIAQLAAQE&sclient=gws-wiz-serp&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=7446856238919548287&lqi=CiFsb2NhbGlzYXRpb24gZW50cmVwcmlzZSBUSVIgdml0aWKSARBzb2Z0d2FyZV9jb21wYW55qgE9EAEqGCIUZW50cmVwcmlzZSB0aXIgdml0aWIoBDIfEAEiG76du6Bx9r5ESwXhC46ESDM6wPh2H86nZoyvEA&sa=X&rlst=f#rlfi=hd:;si:7446856238919548287,l,CiFsb2NhbGlzYXRpb24gZW50cmVwcmlzZSBUSVIgdml0aWKSARBzb2Z0d2FyZV9jb21wYW55qgE9EAEqGCIUZW50cmVwcmlzZSB0aXIgdml0aWIoBDIfEAEiG76du6Bx9r5ESwXhC46ESDM6wPh2H86nZoyvEA;mv:[[5.230545900000001,-3.7576490999999996],[5.2303352,-3.7600782999999995]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2" target="_blank">

                            <div className="iconLink02">
                              
                            
                            <img className='iconIm' src={GoogleMapTir} alt="google map"  />
                            
                              
                          </div>
                        </a>
                        <a href="info@tir-ci" target="_blank"  rel="noreferrer">

                            <div className="iconLink02">
                              
                            <img className='iconIm' src={GoogleGmail} alt="Gmail icon"  />
                            
                              
                          </div>
                        </a>
                    
                    
                
            
            </div>
            </div>
            
            <div className="imgAccueil AnimationDiv">
                <img className="cache01" src={accueilim} alt="accueil im"  />
                <img className="cache02" src={AccueilImage2} alt="accueil im"  />
            </div>
        </div>
        

        
      
    </div>
  )
}

export default Accueil
