import React, {useState, useEffect} from 'react'
import ingApp from '../Assets/ingapp.svg'
import ingcyber from '../Assets/ingCyber.svg'
import incloud from '../Assets/ingCloud.svg'
import inmicro from '../Assets/ingMicro.svg'
import dpifront from '../Assets/dpifront.jpeg'

const Services = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const element = document.querySelector(".AnimationDiv");
        const position = element.getBoundingClientRect();

        if (position.top < window.innerHeight) {
        setIsVisible(true);
        }
    };
  return (
    <>
        <div id='Services' className={`Services pousserServices ${isVisible ? "AnimationDiv" : ""}`}>
        <div className="tiltleServ">
            <span>Nos Services</span>
        </div>
        <div className="descrServ">
            <p>Notre expertise dans les domaines ci-dessous nous permet
            de créer des solutions digitales innovantes pour vous aider à atteindre vos objectifs.</p>
        </div>
        <div className="ListServices">
            <div className="servicesEle">
                <div className="iconService">
                    <img src={ingApp} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie du développement 
                    d’application mobile, web et UX.</span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={ingcyber} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>IIngénierie <br />
                    de la cybersécurité 
                    </span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={incloud} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie des <br />
                    infrastructures cloud</span>
                </div>

            </div>
            <div className="servicesEle">
                <div className="iconService">
                    <img src={inmicro} alt="d’application mobile, web et UX."  />
                </div>
                <div className="titleservices">
                    <span>Ingénierie logicielle <br /> et microservices</span>
                </div>

            </div>
        </div>
        </div>

        <div className="servicedeploie  margin-to">
        <div className={`Services solutionsServices ${isVisible ? "AnimationDiv" : ""}`}>
        <div className="tiltleServ">
            <span>Nos Solutions déja déployées</span>
        </div>
        <div className="descrServ">
            <p>sit amet consectetur adipisicing elit.
            Consequatur mollitia officiis cum accusamus repellendus tempora recusandae</p>
        </div>
        <div className="ListSolutions ">
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <span className='textsolution'>sit amet consectetur adipisicing elit. Consequatur
                        mollitia officiis cum accusamus </span>
                </div>

            </div>
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <span className='textsolution'>sit amet consectetur adipisicing elit. Consequatur
                        mollitia officiis cum accusamus </span>
                </div>

            </div>
            <div className="servicesElement">
                <div className="SolutionImage">
                    <img src={dpifront} alt="d’application mobile, web et UX."  />
                    
                
                </div>
                
                <div className="titlesolution">
                    <div className="titilesolu">
                    <span>Logiciel DPI <br />
                        <span>(Dossier patient informatisé)</span></span>
                </div>
                    <span className='textsolution'>sit amet consectetur adipisicing elit. Consequatur
                        mollitia officiis cum accusamus </span>
                </div>

            </div>
            
            
            
            
            
        </div>
        </div>
        </div>
    </>
    
  )
}

export default Services
