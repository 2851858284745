import React from 'react'
import temoinImg from '../Assets/TemoiImg.jpeg'
import IconAcco from '../Assets/iconAcco.svg'

const Temoignages = () => {
  return (
    <div id='Temoignages' className='Temoignages AnimationDiv'>
      <div className="temoignagetitle">
        <div className="Whytitle">
                <div className="tiltleServ">
                    <span>LES TÉMOIGNAGES</span>
                </div>
                <div className="descrServ">
                    <p>
                        Nos clients satisfaits incluent des partenaires prestigieux 
                        qui ont fait confiance à notre expérience et à la qualité de nos services.

                    </p>
                </div>
        </div>
      </div>
      <div className="barnnerTemoi">
            <div className="elementTemoi">
              <div className="Accola">
                <img src={IconAcco} alt="accolate" />
              </div>
              <div className="textTemoi">
                <p> Nous avons été très satisfaits de la qualité des services de TIR pour notre projet de développement d'application mobile. Leur expertise technique et leur professionnalisme nous ont permis de livrer un produit final de haute qualité dans les délais impartis. </p>
              </div>
              <div className="IdentiteTemoi">
                <div className="ImageTemoi">
                    <img src={temoinImg} alt="Temoin"  />
                </div>
                <div className="NomTemoi">
                  <div className="NomPrenom">
                    <span>Nom & Prenom</span>
                  </div>
                  <div className="fonctionTemoi">
                    <p>sit amet consectetur adipisicing 
                          Consequatur mollitia.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="elementTemoi">
              <div className="Accola">
                <img src={IconAcco} alt="accolate" />
              </div>
              <div className="textTemoi">
                <p> Nous avons été très satisfaits de la qualité des services de TIR pour notre projet de développement d'application mobile. Leur expertise technique et leur professionnalisme nous ont permis de livrer un produit final de haute qualité dans les délais impartis. </p>
              </div>
              <div className="IdentiteTemoi">
                <div className="ImageTemoi">
                    <img src={temoinImg} alt="Temoin"  />
                </div>
                <div className="NomTemoi">
                  <div className="NomPrenom">
                    <span>Nom & Prenom</span>
                  </div>
                  <div className="fonctionTemoi">
                    <p>sit amet consectetur adipisicing 
                          Consequatur mollitia.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="elementTemoi cacheDiv">
              <div className="Accola">
                <img src={IconAcco} alt="accolate" />
              </div>
              <div className="textTemoi">
                <p> Nous avons été très satisfaits de la qualité des services de TIR pour notre projet de développement d'application mobile. Leur expertise technique et leur professionnalisme nous ont permis de livrer un produit final de haute qualité dans les délais impartis. </p>
              </div>
              <div className="IdentiteTemoi">
                <div className="ImageTemoi">
                    <img src={temoinImg} alt="Temoin"  />
                </div>
                <div className="NomTemoi">
                  <div className="NomPrenom">
                    <span>Nom & Prenom</span>
                  </div>
                  <div className="fonctionTemoi">
                    <p>sit amet consectetur adipisicing 
                          Consequatur mollitia.</p>
                  </div>
                </div>
              </div>
            </div>
            
      </div>
    </div>
  )
}

export default Temoignages
