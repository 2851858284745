import React from 'react'
import TIRlocal from '../Assets/TIR-local.jpg'

const Propos = () => {
  return (
    <div id='Propos' className='Propos AnimationDiv'>
      <div className="presentationTIR">
            <div className="tittleTIR">
                <span>QUI SOMMES NOUS ?</span>
            </div>
            <div className="descriptionTIR">
                <p>TIR est une entreprise privée du digital, crée en 2021 et est basée
                au Village des Technologies de l'Information et de la Biotechnologie (VITIB). Chez TIR, nous offrons des services sur
                mesure pour répondre aux besoins de votre entreprise, que vous soyez basé en Côte d'Ivoire ou à l'étranger.
            </p>
            </div>
      </div>
      <div className="imageTIR">
        <img src={TIRlocal} alt="" />
      </div>
    </div>
  )
}

export default Propos
