import './Components/Styles/styles.scss'


import NavBar from './Components/NavBar/NavBar';
import Accueil from './Components/Sections/Acceul';

import ImGaNIME from './Components/Assets/backgroundanim.svg'
import BodyComponents from './Components/Sections/BodyComponents';



function App() {
  
  return (
    < >
      <div className="ConteneurAnime">
        <img src={ImGaNIME} alt="anime"  />
        
      </div>
      <div className="ConteneurAnime2">
        <img src={ImGaNIME} alt="anime"  />
        
      </div>
      <NavBar />
      <Accueil />
      <BodyComponents />
      
      
      
    </>
      
    
  );
}

export default App;
